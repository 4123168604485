<template>
  <main style="padding-top: 100px;">
    <div class="page_header element_to_stick">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="search_bar_list">
              <b-input :class="{ searched :searchTerm.length }" type="text" @keyup.enter="search" v-model="searchTerm" class="form-control" placeholder="Cauta restaurant, oras..."></b-input>
              <button v-if="searchTerm.length" class="reset" type="submit" @click="resetSearch()"><i class="mdi mdi-close-box"></i></button>
              <button type="submit" @click="search"><i class="mdi mdi-magnify"></i></button>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
    </div>
    <!-- /page_header -->

    <div class="container margin_30_20">
      <b-row>
        <b-col md="4" sm="12" v-for="(restaurant, index) in restaurants" :key="index">
          <div class="strip">
            <figure>
              <b-img :src="restaurant.image_url" fluid-grow></b-img>
              <router-link class="strip_info" :to="{ name: 'public.restaurant.details', params: { slug: restaurant.slug } }">
                <small class="inchis" v-if="!restaurant.is_opened">Precomanda</small>
                <small id="estimated-time" v-if="restaurant.is_opened && restaurant.estimated_time"><i class="mdi pr-1 mdi-timer-outline "></i>{{restaurant.estimated_time}}</small>
                <small id="text-promo" v-if="restaurant.text_promo">{{restaurant.text_promo}}</small>
                <div class="item_title">
                  <h3>{{ restaurant.name }}</h3>
                  <small>{{ restaurant.street }}, {{ restaurant.city }}</small>
                </div>
              </router-link>
            </figure>
          </div>
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import PublicRestaurants from '@/components/publicEndpoints/restaurants'
import axios from "axios";

export default {
  name: 'Restaurants',
  data() {
    return {
      restaurants: [],
      paging: {
        page: 1,
        per_page: 12,
        total: 10,
        lastPage: 1000
      },
      filters: {},
      searchTerm: this.$route.params.searchTerm || '',
      userLocation: null,
      lazyLoadingBusy: false,
      endOfResults: false,
      isBusy: false
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
  },
  methods: {
    resetSearch() {
      this.searchTerm = '';
      this.search();
    },
    search() {
      this.endOfResults = false;
      this.filters = {};
      this.paging.search = this.searchTerm
      this.paging.page = 1
      this.paging.lastPage = 1000
      this.getRestaurants(true)
    },
    getRestaurants(clearResults = false) {
      if (this.endOfResults || this.isBusy) {
        return;
      }
      this.paging.search = this.searchTerm;
      this.isBusy = true
      PublicRestaurants.getAll(this.paging, this.filters).then(response => {
        if (response.data.data.length === 0) {
          this.endOfResults = true;
        }

        if (clearResults) {
          this.restaurants = response.data.data
        } else {
          response.data.data.forEach(restaurant =>  {
            if (!this.restaurantExists(restaurant.id)) {
              this.restaurants.push(restaurant)
            }
          })
        }
        this.paging.total = response.data.meta.total
        this.paging.lastPage = response.data.meta.last_page
        this.lazyLoadingBusy = false;
        this.isBusy = false
        if (!this.restaurants.length && this.searchTerm !== '') {
          this.$swal(
              'Nici un rezultat',
              'Nu am gasit nici un rezultat pentru cautarea:<br>' + this.searchTerm + '<br>Alege restaurantul pe care il doresti pentru a vizualiza meniul',
              'warning'
          );
          this.searchTerm = '';
          this.search();
          //this.$router.push({ name: 'public.cities' })
        }
      })
    },
    handleScroll: function(e) {
      if (this.lazyLoadingBusy) {
        return;
      }
      const bodyHeight = e.target.body.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const beforeBottom = 500;
      if ((innerHeight + scrollY) >= bodyHeight - beforeBottom) {
        this.lazyLoadingBusy = true;
        this.paging.page++;
        this.getRestaurants()
      }
    },
    restaurantExists(id) {
      return this.restaurants.some(el => {
        return el.id === id;
      });
    }
  },
  mounted() {
    //this.search()
    //this.askLocation();
    this.getRestaurants(true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>


<style scoped>
#estimated-time {
  /* font-weight: bold; */
  background: #4E7BC5;
  color: #fff;
  left: 0;
  border-radius: 0;
}
#text-promo {
  top: 37px;
  left: 0;
  background: red;
  color: white;
  border-radius: 0;
}
.inchis {
  left: 0!important;
  border-radius: 0!important;
}
.searched {
  box-shadow: 0px 0px 0px 2px #4E7BC5!important;
  padding-left: 45px!important;
}
.reset {
  left: 0px;
  width: 20px;
}
.mdi-magnify {
  font-size: 28px;
}
</style>